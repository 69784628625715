// import React, { useEffect, useState, useCallback, lazy } from "react";
// import "../Styles/HomePage.css";
// import axios from "axios";
// import { useMediaQuery } from "react-responsive";
// import { API_ROOT, webPath } from "../apiconfig";
// import jsonData from "../Test.json"
// const TabContent = lazy(() => import("react-bootstrap").then((module) => ({ default: module.TabContent })));
// const Tabs = lazy(() => import("react-bootstrap").then((module) => ({ default: module.Tabs })));
// const Tab = lazy(() => import("react-bootstrap").then((module) => ({ default: module.Tab })));
// const DataComp = lazy(() => import("../Component/DataComp").then((module) => ({ default: module.DataComp })));
// const TextCard = lazy(() => import("../Component/TextCard"));



// const preloadResources = (imagePath) => {
//   const imgLink = document.createElement("link");
//   imgLink.rel = "preload";
//   imgLink.href = `${webPath}/${imagePath}`;
//   imgLink.as = "image";
//   document.head.appendChild(imgLink);
// };

// const generateSrcSet = (imagePath, widths) => {
//   return widths
//     .map((width) => `${imagePath}?width=${width} ${width}w`)
//     .join(", ");
// };

// const Homepage = () => {
//   const [activeKey, setActiveKey] = useState("tab1");
//   // const [newData, setNewData] = useState([]);
//   const [newData, setNewData] = useState([jsonData.homelatest]);
//   const [latestData, setLatestData] = useState([]);
//   const [popularData, setPopularData] = useState([]);
//   const [advertisementData, setAdvertisementData] = useState([]);
//   // eslint-disable-next-line
//   const [isLoading, setIsLoading] = useState(true);

//   const handleTabSelect = useCallback((key) => {
//     setActiveKey(key);
//   }, []);

//   const fetchData = useCallback(async (url, setter) => {
//     try {
//       const response = await fetch(url);
//       if (!response.ok) throw new Error("Error fetching data");
//       const data = await response.json();
//       setter(data);
//     } catch (error) {
//       console.error(error);
//     }
//   }, []);

//   useEffect(() => {
//     const fetchAllData = async () => {
//       await Promise.allSettled([
//         fetchData(`${API_ROOT}/api/post/homelatest`, setNewData),
//         fetchData(`${API_ROOT}/api/post/homelatestnews`, setLatestData),
//         fetchData(`${API_ROOT}/api/post/homepopular`, setPopularData),
//         fetchAdvertisementData(),
//       ]);
//       setIsLoading(false);
//     };

//     const fetchAdvertisementData = async () => {
//       try {
//         const response = await axios.get(`${API_ROOT}/api/advertisement/get_active`);
//         setAdvertisementData(response.data);
//       } catch (error) {
//         console.error("Error fetching advertisement data:", error);
//       }
//     };

//     fetchAllData();
//   }, [fetchData]);


//   const displayedData = activeKey === "tab1" ? latestData : popularData;
//   const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
//   const dataCompWidth = isLargeScreen ? 500 : 300;

//   return (
//     <div className="container container-max mt-3 main-page" style={{ overflow: "hidden" }}>
//       <div className="row">
//         <div className="col-12">
//           <h1 className="fw-bold borderB py-1 h5">Latest</h1>
//         </div>

//         <div className="col-md-9 col-12 container borderR" style={{ minHeight: "700px" }}>
//           <>
//             {newData.length > 0 && (
//               <a className="text-decoration-none text-black" href={`${newData[0]?.cat_slug}/${newData[0]?.post_name}`}>
//                 <DataComp
//                   src={`${webPath}${newData[0]?.banner_img}?width=${isLargeScreen ? 700 : 200}`}
//                   srcSet={generateSrcSet(`${webPath}${newData[0]?.banner_img}`, [150, 300, 500, 700, 1000, 1200])}
//                   alt={newData[0]?.post_name}
//                   h2Title={newData[0]?.post_title}
//                   p_Desc={newData[0]?.post_content}
//                   post_author={newData[0]?.post_author}
//                   post_date={newData[0]?.post_date}
//                   withZoom={false}
//                   loading="lazy"
//                   fetchpriority="high"
//                 />
//               </a>
//             )}

//           </>
//           <div className="mainSecondBox mt-3" style={{ overflow: "hidden" }}>
//             <div className="row no-gutters">
//               {Array.isArray(newData) &&
//                 newData?.slice(1, 3)?.map((item) => (
//                   <div key={item.id} className="col-md-6 col-12" style={{ minHeight: "300px" }}>
//                     <a className="text-decoration-none text-black" href={`${item?.cat_slug}/${item?.post_name}`}>
//                       {preloadResources(`${item?.banner_img}`)}

//                       <DataComp
//                         src={`${webPath}${item?.banner_img}?width=${dataCompWidth}`}
//                         srcSet={generateSrcSet(`${webPath}${item?.banner_img}`, [150, 300, 500, 700, 1000, 1200])}
//                         alt={item?.post_name}
//                         h2Title={item?.post_title}
//                         p_Desc={item?.post_content}
//                         post_author={item?.post_author}
//                         post_date={item?.post_date}
//                         withZoom={true}
//                         loading="lazy"
//                       />

//                     </a>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>

//         <div className="col-md-3 col-12" style={{ minHeight: "500px" }}>
//           <Tabs activeKey={activeKey} onSelect={handleTabSelect} id="tabs-example" className="tabBtn nav-link1 colrtab">
//             {["Latest", "Popular"].map((tab, index) => (
//               <Tab key={`tab-${index}`} eventKey={`tab${index + 1}`} title={tab} className="text-black">
//                 <TabContent className="marTop">
//                   <div className="paddings">
//                     {displayedData.map((x) => (
//                       <a key={x.id} href={`${x.cat_slug}/${x.post_name}`} className="a-tag">

//                         <TextCard title={x.post_title} desc={x.post_content} post_author={x.post_author} post_date={x.post_date} />

//                       </a>
//                     ))}
//                   </div>
//                 </TabContent>
//               </Tab>
//             ))}
//           </Tabs>

//           <div className="marTop heightAuto" style={{ textAlign: "center", height: "400px", minHeight: "300px" }}>
//             {advertisementData && advertisementData.length > 0 && (
//               <a href={`${advertisementData[1].dest_url}`} aria-label="Visit advertisement page">
//                 <img
//                   className="mt-5"
//                   style={{ height: "300px", width: "auto" }}
//                   src={`${webPath}${advertisementData[1].banner_img}?width=600`}
//                   alt={advertisementData[1].banner_name}
//                   aria-label={advertisementData[1].banner_name}
//                   loading="lazy"
//                   width="640"
//                   height="360"
//                 />
//               </a>
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Homepage;


import React, { useState, useCallback, lazy } from "react";
import "../Styles/HomePage.css";
import { useMediaQuery } from "react-responsive";
import { webPath } from "../apiconfig";
import jsonData from "../data.json"
const TabContent = lazy(() => import("react-bootstrap").then((module) => ({ default: module.TabContent })));
const Tabs = lazy(() => import("react-bootstrap").then((module) => ({ default: module.Tabs })));
const Tab = lazy(() => import("react-bootstrap").then((module) => ({ default: module.Tab })));
const DataComp = lazy(() => import("../Component/DataComp").then((module) => ({ default: module.DataComp })));
const TextCard = lazy(() => import("../Component/TextCard"));


const preloadResources = (imagePath) => {
  const imgLink = document.createElement("link");
  imgLink.rel = "preload";
  imgLink.href = `${webPath}/${imagePath}`;
  imgLink.as = "image";
  document.head.appendChild(imgLink);
};

const generateSrcSet = (imagePath, widths) => {
  return widths
    .map((width) => `${imagePath}?width=${width} ${width}w`)
    .join(", ");
};

const Homepage = () => {
  const [activeKey, setActiveKey] = useState("tab1");

  // Assigning data from JSON
  const newData = jsonData[0].homelatest || [];
  const latestData = jsonData[0].homelatestnews || [];
  const popularData = jsonData[0].homepopular || [];

  
console.log("jsonnn",jsonData[0].homelatest)

  const handleTabSelect = useCallback((key) => {
    setActiveKey(key);
  }, []);

  const displayedData = activeKey === "tab1" ? latestData : popularData;
  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dataCompWidth = isLargeScreen ? 500 : 300;

  return (
    <div className="container container-max mt-3 main-page" style={{ overflow: "hidden" }}>
      <div className="row">
        <div className="col-12">
          <h1 className="fw-bold borderB py-1 h5">Latest</h1>
        </div>

        <div className="col-md-9 col-12 container borderR" style={{ minHeight: "700px" }}>
          <>
            {newData.length > 0 && (
              <a className="text-decoration-none text-black" href={`${newData[0]?.cat_slug}/${newData[0]?.post_name}`}>
                <DataComp
                  src={`${webPath}${newData[0]?.banner_img}?width=${isLargeScreen ? 700 : 200}`}
                  srcSet={generateSrcSet(`${webPath}${newData[0]?.banner_img}`, [150, 300, 500, 700, 1000, 1200])}
                  alt={newData[0]?.post_name}
                  h2Title={newData[0]?.post_title}
                  p_Desc={newData[0]?.post_content}
                  post_author={newData[0]?.post_author}
                  post_date={newData[0]?.post_date}
                  withZoom={false}
                  loading="lazy"
                  fetchpriority="high"
                />
              </a>
            )}
          </>
          <div className="mainSecondBox mt-3" style={{ overflow: "hidden" }}>
            <div className="row no-gutters">
              {Array.isArray(newData) &&
                newData?.slice(1, 3)?.map((item) => (
                  <div key={item.id} className="col-md-6 col-12" style={{ minHeight: "300px" }}>
                    <a className="text-decoration-none text-black" href={`${item?.cat_slug}/${item?.post_name}`}>
                      {preloadResources(`${item?.banner_img}`)}
                      <DataComp
                        src={`${webPath}${item?.banner_img}?width=${dataCompWidth}`}
                        srcSet={generateSrcSet(`${webPath}${item?.banner_img}`, [150, 300, 500, 700, 1000, 1200])}
                        alt={item?.post_name}
                        h2Title={item?.post_title}
                        p_Desc={item?.post_content}
                        post_author={item?.post_author}
                        post_date={item?.post_date}
                        withZoom={true}
                        loading="lazy"
                      />
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="col-md-3 col-12" style={{ minHeight: "500px" }}>
          <Tabs activeKey={activeKey} onSelect={handleTabSelect} id="tabs-example" className="tabBtn nav-link1 colrtab">
            {["Latest", "Popular"].map((tab, index) => (
              <Tab key={`tab-${index}`} eventKey={`tab${index + 1}`} title={tab} className="text-black">
                <TabContent className="marTop">
                  <div className="paddings">
                    {displayedData.map((x) => (
                      <a key={x.id} href={`${x.cat_slug}/${x.post_name}`} className="a-tag">
                        <TextCard title={x.post_title} desc={x.post_content} post_author={x.post_author} post_date={x.post_date} />
                      </a>
                    ))}
                  </div>
                </TabContent>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
