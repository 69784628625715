import React, { useEffect, useState, useCallback } from "react";
import "../Styles/Podcast.css";
import { CardComp } from "../Component/CardComp";
import axios from "axios";
import { API_ROOT, webPath } from "../apiconfig";
import { useMediaQuery } from "react-responsive";

const PodcastComp = ({ order }) => {

  const [data, setData] = useState(() => {
    const localData = localStorage.getItem("podcastData");
    return localData ? JSON.parse(localData) : [];
  });

  const isLargeScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const dataCompWidth = isLargeScreen ? 500 : 200;

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_ROOT}/api/post/homepodcast`
      );
      const fetchedData = response.data;



      setData(fetchedData);


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  useEffect(() => {

    fetchData();



  }, [fetchData]);

  if (!data.length) return null;

  return (
    <div>
      {data && data.length > 0 && (
        <div className="container container-max mt-5 mb-5">
          <div className="row">
            <h3 className="fw-bold borderB py-1 h4">
              {order ? "Guest Author" : "Podcast"}
            </h3>

            <div className={`col-md-8 m-auto ${order ? "order-md-2" : ""}`}>
              <a
                className="a-link text-black"
                href={`/${data[0]?.cat_slug}/${data[0]?.post_name}`}
              >
                <p className="fw-bold hoverHead" style={{ fontSize: "40px" }}>{data[0]?.post_title}</p>
                <p>{data[0]?.post_content}</p>
                <button className="podBtn mt-2">Hear The Podcast</button>
              </a>
            </div>

            <div className={`col-md-4 m-auto mt-3 ${order ? "order-md-1" : ""}`}>
              <CardComp
                src={`${webPath}${data[0]?.banner_img}?width=${dataCompWidth}`}
                alt={data[0]?.post_name}
                cardImg="cardImg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(PodcastComp);
